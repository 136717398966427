import React, { useState } from "react";
import "./Cards.css";
import CardItem from "./CardItem";
import "./Callouts.css";

const CALLOUTS_SECTIONS = [
  {
    id: "0",
    title: "IT Services",
    description:
      "With over 20 years of certified experience in information technology and networking, we can serve any of your IT needs.",
  },
  {
    id: "1",
    title: "Remote Support",
    description:
      "If you ever have any questions or issues, just give us a call. We provide remote support and technical assistance with all of our projects so you’ll never worry about getting a tech on the line.",
  },
  {
    id: "2",
    title: "Design Services",
    description:
      "When you need a website designed and deployed, a logo updated to your specifications, or a brochure or print campaign mocked up, reach out to us for expert and affordable design services.",
  },
  {
    id: "3",
    title: "Cybersecurity",
    description:
      "Your business and its online reputation might hinge on the security of your website and the devices and software you use. Our cybersecurity services can help you safeguard all of them.",
  },
];

let width = screen.width;

function Cards() {
  const [idChosen, setIdChosen] = useState(-1);
  let width = screen.width < 605;
  console.log(width);

  const setIDHandler = (number) => {
    if (number === idChosen) {
      setIdChosen(-1);
    } else {
      setIdChosen(number);
      console.log(number);
    }
  };

  return (
    <div>
      <div className="cards">
        <h1 className="cards__header">SERVICES</h1>
        <div className="cards__container">
          {/* {width && ( */}
          <div className="cards__wrapper__mobile">
            <ul className="cards__items">
              <CardItem
                src="./images/techsupport.png"
                text="IT Services"
                label="IT"
                id="0"
                onSelection={setIDHandler}
              />
              <CardItem
                src="./images/earthnight.png"
                text="Remote Support"
                label="Support"
                id="1"
                onSelection={setIDHandler}
              />
            </ul>
            <ul className="cards__items">
              <CardItem
                src="./images/graphics.png"
                text="Design Services"
                label="Design"
                id="2"
                onSelection={setIDHandler}
              />
              <CardItem
                src="./images/security.png"
                text="Cybersecurity"
                label="Security"
                id="3"
                onSelection={setIDHandler}
              />
            </ul>
          </div>
          {/* )} */}
          {/* // {!width && ( */}
          <div className="cards__wrapper">
            <div className="cards__wrapper">
              <ul className="cards__items">
                <CardItem
                  src="./images/techsupport.png"
                  text="IT Services"
                  label="IT"
                  id="0"
                  onSelection={setIDHandler}
                />
                <CardItem
                  src="./images/earthnight.png"
                  text="Remote Support"
                  label="Support"
                  id="1"
                  onSelection={setIDHandler}
                />
                {/* </ul>
            <ul className="cards__items"> */}
                <CardItem
                  src="./images/graphics.png"
                  text="Design Services"
                  label="Design"
                  id="2"
                  onSelection={setIDHandler}
                />
                <CardItem
                  src="./images/security.png"
                  text="Cybersecurity"
                  label="Security"
                  id="3"
                  onSelection={setIDHandler}
                />
              </ul>
            </div>
          </div>
          {/* )} */}
        </div>
      </div>
      {idChosen === -1 && <></>}
      {idChosen != -1 && (
        <div className="callout">
          <div className="callout-title">
            <label>{CALLOUTS_SECTIONS[idChosen.toString()].title}</label>
          </div>
          <div className="callout-description">
            {CALLOUTS_SECTIONS[idChosen.toString()].description}
          </div>
        </div>
      )}
    </div>
  );
}

export default Cards;
